var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-overlay",
    {
      attrs: {
        show: _vm.loadingPrice || _vm.loadingServiceRequested,
        rounded: "sm"
      }
    },
    [
      _c("h4", { staticClass: "text-primary" }, [
        _vm._v(_vm._s(_vm.$t("services.request")))
      ]),
      _c(
        "b-form",
        {
          on: {
            submit: function($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.onFormSubmit($event)
            }
          }
        },
        [
          _c(
            "b-form-row",
            [
              _c(
                "b-col",
                { attrs: { lg: "12" } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "service-input-group",
                        label: _vm.$t("services.service"),
                        "label-for": "service"
                      }
                    },
                    [
                      _c(
                        "multiselect",
                        {
                          class: { "is-invalid": _vm.$v.form.service.$error },
                          attrs: {
                            id: "service",
                            "close-on-select": true,
                            "clear-on-select": false,
                            "aria-autocomplete": false,
                            multiple: false,
                            placeholder: _vm.$t("select_placeholder"),
                            "select-label": _vm.$t("multiselect.selectLabel"),
                            "selected-label": _vm.$t(
                              "multiselect.selectedLabel"
                            ),
                            "deselect-label": _vm.$t(
                              "multiselect.deselectLabel"
                            ),
                            "track-by": "id",
                            options: _vm.services,
                            state: _vm.validateState("service"),
                            "aria-describedby": "service-live-feedback"
                          },
                          on: { input: _vm.onServiceOrAffiliateChanged },
                          scopedSlots: _vm._u([
                            {
                              key: "option",
                              fn: function(ref) {
                                var option = ref.option
                                return [
                                  _vm._v(
                                    " " + _vm._s(option.categoria.name) + " "
                                  )
                                ]
                              }
                            },
                            {
                              key: "singleLabel",
                              fn: function(ref) {
                                var option = ref.option
                                return [
                                  _vm._v(
                                    " " + _vm._s(option.categoria.name) + " "
                                  )
                                ]
                              }
                            }
                          ]),
                          model: {
                            value: _vm.$v.form.service.$model,
                            callback: function($$v) {
                              _vm.$set(_vm.$v.form.service, "$model", $$v)
                            },
                            expression: "$v.form.service.$model"
                          }
                        },
                        [
                          _c("template", { slot: "noOptions" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("multiselect.noOptionsLabel")) +
                                " "
                            )
                          ]),
                          _c("template", { slot: "noResult" }, [
                            _vm._v(
                              _vm._s(_vm.$t("multiselect.noResultLabel")) + " "
                            )
                          ])
                        ],
                        2
                      ),
                      _c(
                        "b-form-invalid-feedback",
                        { attrs: { id: "service-live-feedback" } },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("validation.required")) + " "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.form.service
            ? _c(
                "b-form-row",
                { staticClass: "mb-2" },
                [
                  _c("b-col", { attrs: { lg: "12" } }, [
                    _c("div", { staticClass: "p-2 bg-secondary text-white" }, [
                      _vm._v(" " + _vm._s(_vm.form.service.description) + " ")
                    ])
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm.serviceDescription
            ? _c(
                "b-form-row",
                { staticClass: "mb-2" },
                [
                  _c("b-col", { attrs: { lg: "12" } }, [
                    _c("p", [_vm._v(_vm._s(_vm.serviceDescription))])
                  ])
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-form-row",
            [
              _c(
                "b-col",
                { attrs: { lg: "6" } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "request_dateStart-input-group",
                        label: _vm.$t("services.request_dateStart"),
                        "label-for": "request_dateStart"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "request_dateStart",
                          type: "date",
                          disabled: !!_vm.form.days,
                          state: _vm.validateState("request_dateStart"),
                          "aria-describedby": "request_dateStart-live-feedback"
                        },
                        on: { change: _vm.updateServiceDescription },
                        model: {
                          value: _vm.$v.form.request_dateStart.$model,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.$v.form.request_dateStart,
                              "$model",
                              $$v
                            )
                          },
                          expression: "$v.form.request_dateStart.$model"
                        }
                      }),
                      _c(
                        "b-form-invalid-feedback",
                        { attrs: { id: "request_dateStart-live-feedback" } },
                        [
                          !_vm.$v.form.request_dateStart.required
                            ? _c("div", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("validation.required")) +
                                    " "
                                )
                              ])
                            : _vm._e(),
                          !_vm.$v.form.request_dateStart.minValue
                            ? _c("div", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("validation.past_date")) +
                                    " "
                                )
                              ])
                            : _vm._e(),
                          !_vm.$v.form.request_dateStart.isSaturday
                            ? _c("div", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("validation.not_saturday")) +
                                    " "
                                )
                              ])
                            : _vm._e()
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-col",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showTime,
                      expression: "showTime"
                    }
                  ],
                  attrs: { lg: "6" }
                },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "request_timeStart-input-group",
                        label: _vm.$t("services.request_timeStart"),
                        "label-for": "request_timeStart"
                      }
                    },
                    [
                      _c("vue-timepicker", {
                        class:
                          _vm.$v.form.request_timeStart.$dirty &&
                          (!_vm.$v.form.request_timeStart.required ||
                            !_vm.$v.form.request_timeStart.selected)
                            ? "is-invalid"
                            : "",
                        attrs: {
                          id: "request_timeStart",
                          format: "HH:mm",
                          "minute-interval": 15,
                          disabled: !_vm.showTime,
                          "manual-input": "",
                          "hide-dropdown": "",
                          state: _vm.validateState("request_timeStart"),
                          "aria-describedby": "request_timeStart-live-feedback"
                        },
                        on: { change: _vm.updateServiceDescription },
                        model: {
                          value: _vm.$v.form.request_timeStart.$model,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.$v.form.request_timeStart,
                              "$model",
                              $$v
                            )
                          },
                          expression: "$v.form.request_timeStart.$model"
                        }
                      }),
                      _vm.$v.form.request_timeStart.$dirty
                        ? _c("div", [
                            !_vm.$v.form.request_timeStart.required ||
                            !_vm.$v.form.request_timeStart.selected
                              ? _c(
                                  "div",
                                  { staticClass: "d-block invalid-feedback" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("validation.required")) +
                                        " "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { lg: "4" } },
                [
                  _c(
                    "b-form-group",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showDay,
                          expression: "showDay"
                        }
                      ],
                      attrs: {
                        id: "request_days-input-group",
                        label: _vm.$t("services.days"),
                        "label-for": "days-input"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          type: "number",
                          id: "days-input",
                          state: _vm.validateState("days"),
                          "aria-describedby": "days-live-feedback"
                        },
                        on: { input: _vm.onDaysValueChanged },
                        model: {
                          value: _vm.$v.form.days.$model,
                          callback: function($$v) {
                            _vm.$set(_vm.$v.form.days, "$model", $$v)
                          },
                          expression: "$v.form.days.$model"
                        }
                      }),
                      _c(
                        "b-form-invalid-feedback",
                        { attrs: { id: "days-live-feedback" } },
                        [
                          !_vm.$v.form.days.required
                            ? _c("div", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("validation.required_if_not_dates")
                                    ) +
                                    " "
                                )
                              ])
                            : _vm._e()
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-form-row",
            [
              _c(
                "b-col",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.form.days && !this.weekendService,
                      expression: "!form.days && !this.weekendService"
                    }
                  ],
                  attrs: { lg: "6" }
                },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "request_dateEnd-input-group",
                        label: _vm.$t("services.request_dateEnd"),
                        "label-for": "request_dateEnd"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "request_dateEnd",
                          type: "date",
                          disabled: !!_vm.form.days || this.weekendService,
                          state: _vm.validateState("request_dateEnd"),
                          "aria-describedby": "request_dateEnd-live-feedback"
                        },
                        on: { change: _vm.updateServiceDescription },
                        model: {
                          value: _vm.$v.form.request_dateEnd.$model,
                          callback: function($$v) {
                            _vm.$set(_vm.$v.form.request_dateEnd, "$model", $$v)
                          },
                          expression: "$v.form.request_dateEnd.$model"
                        }
                      }),
                      _c(
                        "b-form-invalid-feedback",
                        { attrs: { id: "request_dateEnd-live-feedback" } },
                        [
                          !_vm.$v.form.request_dateEnd.required
                            ? _c("div", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("validation.required")) +
                                    " "
                                )
                              ])
                            : _vm._e(),
                          !_vm.$v.form.request_dateEnd.minValue
                            ? _c("div", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("validation.past_date")) +
                                    " "
                                )
                              ])
                            : _vm._e(),
                          !_vm.$v.form.request_dateEnd.afterStart
                            ? _c("div", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("validation.before_date")) +
                                    " "
                                )
                              ])
                            : _vm._e()
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-col",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showTime,
                      expression: "showTime"
                    }
                  ],
                  attrs: { lg: "6" }
                },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "request_timeEnd-input-group",
                        label: _vm.$t("services.request_timeEnd"),
                        "label-for": "request_timeEnd"
                      }
                    },
                    [
                      _c("vue-timepicker", {
                        class:
                          _vm.$v.form.request_timeEnd.$dirty &&
                          (!_vm.$v.form.request_timeEnd.required ||
                            !_vm.$v.form.request_timeEnd.selected)
                            ? "is-invalid"
                            : "",
                        attrs: {
                          id: "request_timeEnd",
                          format: "HH:mm",
                          "minute-interval": 15,
                          disabled: !_vm.showTime,
                          "manual-input": "",
                          "hide-dropdown": "",
                          state: _vm.validateState("request_timeEnd"),
                          "aria-describedby": "request_timeEnd-live-feedback"
                        },
                        on: { change: _vm.updateServiceDescription },
                        model: {
                          value: _vm.$v.form.request_timeEnd.$model,
                          callback: function($$v) {
                            _vm.$set(_vm.$v.form.request_timeEnd, "$model", $$v)
                          },
                          expression: "$v.form.request_timeEnd.$model"
                        }
                      }),
                      _vm.$v.form.request_timeEnd.$dirty
                        ? _c("div", [
                            !_vm.$v.form.request_timeEnd.required ||
                            !_vm.$v.form.request_timeEnd.selected
                              ? _c(
                                  "div",
                                  { staticClass: "d-block invalid-feedback" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("validation.required")) +
                                        " "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-form-row",
            [
              _c(
                "b-col",
                { attrs: { lg: "6" } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "user_id-input-group",
                        label: _vm.$t("services.user_id"),
                        "label-for": "user_id"
                      }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.$v.form.user_id.$model,
                            expression: "$v.form.user_id.$model"
                          }
                        ],
                        attrs: { type: "hidden" },
                        domProps: { value: _vm.$v.form.user_id.$model },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.$v.form.user_id,
                              "$model",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      _c("b-form-input", {
                        attrs: {
                          id: "user_id",
                          type: "text",
                          value:
                            _vm.$loggedUser().first_name +
                            " " +
                            _vm.$loggedUser().last_name,
                          disabled: ""
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { lg: "6" } },
                [
                  _c(
                    "b-form-group",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showAssistedNameField,
                          expression: "showAssistedNameField"
                        }
                      ],
                      attrs: {
                        id: "assisted_name-input-group",
                        label: _vm.$t("services.assisted_name"),
                        "label-for": "assisted_name"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "assisted_name",
                          type: "text",
                          state: _vm.validateState("assisted_name"),
                          "aria-describedby": "assisted_name-live-feedback"
                        },
                        model: {
                          value: _vm.$v.form.assisted_name.$model,
                          callback: function($$v) {
                            _vm.$set(_vm.$v.form.assisted_name, "$model", $$v)
                          },
                          expression: "$v.form.assisted_name.$model"
                        }
                      }),
                      _c(
                        "b-form-invalid-feedback",
                        { attrs: { id: "assisted_name-live-feedback" } },
                        [
                          !_vm.$v.form.assisted_name.required
                            ? _c("div", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("validation.required")) +
                                    " "
                                )
                              ])
                            : _vm._e()
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-form-row",
            [
              _c(
                "b-col",
                { attrs: { lg: "6" } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "city-input-group",
                        label: _vm.$t("register.city"),
                        "label-for": "city"
                      }
                    },
                    [
                      _c(
                        "multiselect",
                        {
                          class: { "is-invalid": _vm.$v.form.city.$error },
                          attrs: {
                            id: "city",
                            "close-on-select": true,
                            "clear-on-select": false,
                            multiple: false,
                            placeholder: _vm.$t("select_placeholder"),
                            "select-label": _vm.$t("multiselect.selectLabel"),
                            "selected-label": _vm.$t(
                              "multiselect.selectedLabel"
                            ),
                            "deselect-label": _vm.$t(
                              "multiselect.deselectLabel"
                            ),
                            label: "comune",
                            "track-by": "id",
                            options: _vm.cities,
                            state: _vm.validateState("city"),
                            "aria-describedby": "city-live-feedback"
                          },
                          on: {
                            select: _vm.onCityChanged,
                            remove: _vm.onCityRemoved
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "option",
                              fn: function(ref) {
                                var option = ref.option
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(option.comune) +
                                      " (" +
                                      _vm._s(option.province) +
                                      ") "
                                  )
                                ]
                              }
                            },
                            {
                              key: "singleLabel",
                              fn: function(ref) {
                                var option = ref.option
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(option.comune) +
                                      " (" +
                                      _vm._s(option.province) +
                                      ") "
                                  )
                                ]
                              }
                            }
                          ]),
                          model: {
                            value: _vm.$v.form.city.$model,
                            callback: function($$v) {
                              _vm.$set(_vm.$v.form.city, "$model", $$v)
                            },
                            expression: "$v.form.city.$model"
                          }
                        },
                        [
                          _c("template", { slot: "noOptions" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("multiselect.noOptionsLabel")) +
                                " "
                            )
                          ]),
                          _c("template", { slot: "noResult" }, [
                            _vm._v(
                              _vm._s(_vm.$t("multiselect.noResultLabel")) + " "
                            )
                          ])
                        ],
                        2
                      ),
                      _c(
                        "b-form-invalid-feedback",
                        { attrs: { id: "city-live-feedback" } },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("validation.required")) + " "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { lg: "6" } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "address-input-group",
                        label: _vm.$t("services.address"),
                        "label-for": "address"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "address",
                          type: "text",
                          state: _vm.validateState("address"),
                          "aria-describedby": "address-live-feedback"
                        },
                        model: {
                          value: _vm.$v.form.address.$model,
                          callback: function($$v) {
                            _vm.$set(_vm.$v.form.address, "$model", $$v)
                          },
                          expression: "$v.form.address.$model"
                        }
                      }),
                      _c(
                        "b-form-invalid-feedback",
                        { attrs: { id: "address-live-feedback" } },
                        [
                          !_vm.$v.form.address.required
                            ? _c("div", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("validation.required")) +
                                    " "
                                )
                              ])
                            : _vm._e()
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-form-row",
            [
              _c(
                "b-col",
                { attrs: { lg: "6" } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "affiliate-input-group",
                        label: _vm.$t("services.affiliate_family"),
                        "label-for": "affiliate"
                      }
                    },
                    [
                      _c(
                        "b-overlay",
                        {
                          attrs: { show: _vm.loadingAffiliates, rounded: "sm" }
                        },
                        [
                          _c(
                            "multiselect",
                            {
                              class: {
                                "is-invalid": _vm.$v.form.affiliate.$error
                              },
                              attrs: {
                                id: "affiliate",
                                "close-on-select": true,
                                "clear-on-select": false,
                                multiple: false,
                                placeholder: _vm.$t("select_placeholder"),
                                "select-label": _vm.$t(
                                  "multiselect.selectLabel"
                                ),
                                "selected-label": _vm.$t(
                                  "multiselect.selectedLabel"
                                ),
                                "deselect-label": _vm.$t(
                                  "multiselect.deselectLabel"
                                ),
                                label: "ragione_sociale",
                                "track-by": "id",
                                options:
                                  _vm.affiliates.length > 0
                                    ? _vm.affiliates
                                    : [
                                        {
                                          id: "mycare",
                                          ragione_sociale: "Agenzia MyCare"
                                        }
                                      ],
                                state: _vm.validateState("affiliate")
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "option",
                                  fn: function(ref) {
                                    var option = ref.option
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(option.ragione_sociale) +
                                          " "
                                      )
                                    ]
                                  }
                                },
                                {
                                  key: "singleLabel",
                                  fn: function(ref) {
                                    var option = ref.option
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(option.ragione_sociale) +
                                          " "
                                      )
                                    ]
                                  }
                                }
                              ]),
                              model: {
                                value: _vm.$v.form.affiliate.$model,
                                callback: function($$v) {
                                  _vm.$set(_vm.$v.form.affiliate, "$model", $$v)
                                },
                                expression: "$v.form.affiliate.$model"
                              }
                            },
                            [
                              _c("template", { slot: "noOptions" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("multiselect.noResultAffiliate")
                                    ) +
                                    " "
                                )
                              ]),
                              _c("template", { slot: "noResult" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("multiselect.noResultLabel")) +
                                    " "
                                )
                              ])
                            ],
                            2
                          ),
                          _c(
                            "b-form-invalid-feedback",
                            { attrs: { id: "affiliate-live-feedback" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("validation.required")) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "text-right" },
            [
              _c(
                "b-button",
                { attrs: { type: "submit", variant: "success" } },
                [_vm._v(_vm._s(_vm.$t("buttons.service_request")) + " ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-confirmation",
            title: "Riepilogo",
            "ok-title": "Conferma",
            "cancel-title": "Annulla"
          },
          on: { ok: _vm.onModalConfirm }
        },
        [
          _c("p", { staticClass: "my-4" }, [
            _vm._v(" Cliente: "),
            _c("strong", [
              _vm._v(
                _vm._s(
                  _vm.$loggedUser().first_name +
                    " " +
                    _vm.$loggedUser().last_name
                )
              )
            ])
          ]),
          this.form.service
            ? _c("p", { staticClass: "my-4" }, [
                _vm._v(" Servizio richiesto: "),
                _c("strong", [_vm._v(_vm._s(this.form.service.categoria.name))])
              ])
            : _vm._e(),
          _c("p", { staticClass: "my-4" }, [
            _vm._v(" Richiesta servizio: dal "),
            _c("strong", [
              _vm._v(
                _vm._s(
                  new Date(this.form.request_dateStart).toLocaleDateString()
                )
              )
            ]),
            this.form.request_dateEnd
              ? _c("span", [
                  _vm._v(" al "),
                  this.form.service.slug != "presenza_week-end_corto" &&
                  this.form.service.slug != "presenza_week-end_lungo"
                    ? _c("strong", [
                        _vm._v(
                          _vm._s(
                            new Date(
                              this.form.request_dateEnd
                            ).toLocaleDateString()
                          )
                        )
                      ])
                    : _vm._e()
                ])
              : _vm._e()
          ]),
          _c("p", { staticClass: "my-4" }, [
            _vm._v(" Prezzo: "),
            _c("strong", [_vm._v(_vm._s(this.price) + "€")])
          ]),
          this.form.service &&
          this.form.service.categoria.name != "COLF" &&
          this.form.service.categoria.name != "BABYSITTER"
            ? _c("p", { staticClass: "my-4" }, [
                _vm._v(" Assistito: "),
                _c("strong", [_vm._v(_vm._s(this.form.assisted_name))])
              ])
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
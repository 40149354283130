<template>
  <b-overlay :show="loadingPrice || loadingServiceRequested" rounded="sm">
    <h4 class="text-primary">{{ $t("services.request") }}</h4>
    <b-form @submit.stop.prevent="onFormSubmit">
       <!-- Service -->
       <b-form-row>
        <b-col lg="12">
          <b-form-group
            id="service-input-group"
            :label="$t('services.service')"
            label-for="service"
          >
            <multiselect
              id="service"
              :class="{ 'is-invalid': $v.form.service.$error }"
              :close-on-select="true"
              :clear-on-select="false"
              :aria-autocomplete="false"
              :multiple="false"
              :placeholder="$t('select_placeholder')"
              :select-label="$t('multiselect.selectLabel')"
              :selected-label="$t('multiselect.selectedLabel')"
              :deselect-label="$t('multiselect.deselectLabel')"
              track-by="id"
              :options="services"
              v-model="$v.form.service.$model"
              :state="validateState('service')"
              aria-describedby="service-live-feedback"
              @input="onServiceOrAffiliateChanged"
            >
              <template slot="noOptions">
                {{ $t("multiselect.noOptionsLabel") }}
              </template>
              <template slot="noResult"
                >{{ $t("multiselect.noResultLabel") }}
              </template>

              <template slot="option" slot-scope="{ option }">
                {{ option.categoria.name }}
              </template>

              <template slot="singleLabel" slot-scope="{ option }">
                {{ option.categoria.name }}
              </template>
            </multiselect>
            <b-form-invalid-feedback id="service-live-feedback">
              {{ $t("validation.required") }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-form-row>
      <!-- Service Description -->
      <b-form-row v-if="form.service" class="mb-2">
        <b-col lg="12">
          <div class="p-2 bg-secondary text-white">
            {{ form.service.description }}
          </div>
        </b-col>
      </b-form-row>

      <!-- Service Description -->
      <b-form-row v-if="serviceDescription" class="mb-2">
        <b-col lg="12">
          <p>{{ serviceDescription }}</p>
        </b-col>
      </b-form-row>
      <b-form-row>
        <!-- DateStart -->
        <b-col lg="6">
          <b-form-group
            id="request_dateStart-input-group"
            :label="$t('services.request_dateStart')"
            label-for="request_dateStart"
          >
            <b-form-input
              id="request_dateStart"
              type="date"
              :disabled="!!form.days"
              v-model="$v.form.request_dateStart.$model"
              :state="validateState('request_dateStart')"
              aria-describedby="request_dateStart-live-feedback"
              @change="updateServiceDescription"
            ></b-form-input>
            <b-form-invalid-feedback id="request_dateStart-live-feedback">
              <div v-if="!$v.form.request_dateStart.required">
                {{ $t("validation.required") }}
              </div>
              <div v-if="!$v.form.request_dateStart.minValue">
                {{ $t("validation.past_date") }}
              </div>
              <div v-if="!$v.form.request_dateStart.isSaturday">
                {{ $t("validation.not_saturday") }}
              </div>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <!-- TimeStart -->
        <b-col lg="6" v-show="showTime">
          <b-form-group
            id="request_timeStart-input-group"
            :label="$t('services.request_timeStart')"
            label-for="request_timeStart"
          >
            <vue-timepicker
              id="request_timeStart"
              format="HH:mm"
              :minute-interval="15"
              :disabled="!showTime"
              manual-input
              hide-dropdown
              :class="
                $v.form.request_timeStart.$dirty &&
                (!$v.form.request_timeStart.required ||
                  !$v.form.request_timeStart.selected)
                  ? 'is-invalid'
                  : ''
              "
              v-model="$v.form.request_timeStart.$model"
              :state="validateState('request_timeStart')"
              aria-describedby="request_timeStart-live-feedback"
              @change="updateServiceDescription"
            ></vue-timepicker>
            <div v-if="$v.form.request_timeStart.$dirty">
              <div
                v-if="
                  !$v.form.request_timeStart.required ||
                  !$v.form.request_timeStart.selected
                "
                class="d-block invalid-feedback"
              >
                {{ $t("validation.required") }}
              </div>
            </div>
          </b-form-group>
        </b-col>

        <!-- days service -->
        <b-col lg="4">
          <b-form-group
            v-show="showDay"
            id="request_days-input-group"
            :label="$t('services.days')"
            label-for="days-input"
          >
            <b-form-input
              type="number"
              id="days-input"
              v-model="$v.form.days.$model"
              :state="validateState('days')"
              aria-describedby="days-live-feedback"
              @input="onDaysValueChanged"
            />
            <b-form-invalid-feedback id="days-live-feedback">
              <div v-if="!$v.form.days.required">
                {{ $t("validation.required_if_not_dates") }}
              </div>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <!-- DateEnd -->
        <b-col lg="6" v-show="!form.days && !this.weekendService">
          <b-form-group
            id="request_dateEnd-input-group"
            :label="$t('services.request_dateEnd')"
            label-for="request_dateEnd"
          >
            <b-form-input
              id="request_dateEnd"
              type="date"
              :disabled="!!form.days || this.weekendService"
              v-model="$v.form.request_dateEnd.$model"
              :state="validateState('request_dateEnd')"
              aria-describedby="request_dateEnd-live-feedback"
              @change="updateServiceDescription"
            ></b-form-input>
            <b-form-invalid-feedback id="request_dateEnd-live-feedback">
              <div v-if="!$v.form.request_dateEnd.required">
                {{ $t("validation.required") }}
              </div>
              <div v-if="!$v.form.request_dateEnd.minValue">
                {{ $t("validation.past_date") }}
              </div>
              <div v-if="!$v.form.request_dateEnd.afterStart">
                {{ $t("validation.before_date") }}
              </div>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <!-- TimeEnd -->
        <b-col lg="6" v-show="showTime">
          <b-form-group
            id="request_timeEnd-input-group"
            :label="$t('services.request_timeEnd')"
            label-for="request_timeEnd"
          >
            <vue-timepicker
              id="request_timeEnd"
              format="HH:mm"
              :minute-interval="15"
              :disabled="!showTime"
              manual-input
              hide-dropdown
              :class="
                $v.form.request_timeEnd.$dirty &&
                (!$v.form.request_timeEnd.required ||
                  !$v.form.request_timeEnd.selected)
                  ? 'is-invalid'
                  : ''
              "
              v-model="$v.form.request_timeEnd.$model"
              :state="validateState('request_timeEnd')"
              aria-describedby="request_timeEnd-live-feedback"
              @change="updateServiceDescription"
            ></vue-timepicker>
            <div v-if="$v.form.request_timeEnd.$dirty">
              <div
                v-if="
                  !$v.form.request_timeEnd.required ||
                  !$v.form.request_timeEnd.selected
                "
                class="d-block invalid-feedback"
              >
                {{ $t("validation.required") }}
              </div>
            </div>
          </b-form-group>
        </b-col>
      </b-form-row>

      <!-- Client + Patient -->
      <b-form-row>
        <b-col lg="6">
          <b-form-group
            id="user_id-input-group"
            :label="$t('services.user_id')"
            label-for="user_id"
          >
            <input type="hidden" v-model="$v.form.user_id.$model" />
            <b-form-input
              id="user_id"
              type="text"
              :value="$loggedUser().first_name + ' ' + $loggedUser().last_name"
              disabled
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col lg="6">
          <b-form-group
            v-show="showAssistedNameField"
            id="assisted_name-input-group"
            :label="$t('services.assisted_name')"
            label-for="assisted_name"
          >
            <b-form-input
              id="assisted_name"
              type="text"
              v-model="$v.form.assisted_name.$model"
              :state="validateState('assisted_name')"
              aria-describedby="assisted_name-live-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="assisted_name-live-feedback">
              <div v-if="!$v.form.assisted_name.required">
                {{ $t("validation.required") }}
              </div>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-form-row>
      <!-- City + Address -->
      <b-form-row>
        <b-col lg="6">
          <b-form-group
            id="city-input-group"
            :label="$t('register.city')"
            label-for="city"
          >
            <multiselect
              id="city"
              :class="{ 'is-invalid': $v.form.city.$error }"
              :close-on-select="true"
              :clear-on-select="false"
              :multiple="false"
              :placeholder="$t('select_placeholder')"
              :select-label="$t('multiselect.selectLabel')"
              :selected-label="$t('multiselect.selectedLabel')"
              :deselect-label="$t('multiselect.deselectLabel')"
              label="comune"
              track-by="id"
              :options="cities"
              v-model="$v.form.city.$model"
              :state="validateState('city')"
              aria-describedby="city-live-feedback"
              @select="onCityChanged"
              @remove="onCityRemoved"
            >
              <template slot="option" slot-scope="{ option }">
                {{ option.comune }} ({{ option.province }})
              </template>

              <template slot="singleLabel" slot-scope="{ option }">
                {{ option.comune }} ({{ option.province }})
              </template>

              <template slot="noOptions">
                {{ $t("multiselect.noOptionsLabel") }}
              </template>
              <template slot="noResult"
                >{{ $t("multiselect.noResultLabel") }}
              </template>
            </multiselect>
            <b-form-invalid-feedback id="city-live-feedback">
              {{ $t("validation.required") }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col lg="6">
          <b-form-group
            id="address-input-group"
            :label="$t('services.address')"
            label-for="address"
          >
            <b-form-input
              id="address"
              type="text"
              v-model="$v.form.address.$model"
              :state="validateState('address')"
              aria-describedby="address-live-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="address-live-feedback">
              <div v-if="!$v.form.address.required">
                {{ $t("validation.required") }}
              </div>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-form-row>
      <!-- Affiliate + Price -->
      <b-form-row>
        <b-col lg="6">
          <b-form-group
            id="affiliate-input-group"
            :label="$t('services.affiliate_family')"
            label-for="affiliate"
          >
            <b-overlay :show="loadingAffiliates" rounded="sm">
              <multiselect
                id="affiliate"
                :class="{ 'is-invalid': $v.form.affiliate.$error }"
                :close-on-select="true"
                :clear-on-select="false"
                :multiple="false"
                :placeholder="$t('select_placeholder')"
                :select-label="$t('multiselect.selectLabel')"
                :selected-label="$t('multiselect.selectedLabel')"
                :deselect-label="$t('multiselect.deselectLabel')"
                label="ragione_sociale"
                track-by="id"
                :options="
                  affiliates.length > 0
                    ? affiliates
                    : [{ id: 'mycare', ragione_sociale: 'Agenzia MyCare' }]
                "
                v-model="$v.form.affiliate.$model"
                :state="validateState('affiliate')"
              >
                <template slot="option" slot-scope="{ option }">
                  {{ option.ragione_sociale }}
                </template>
                <template slot="singleLabel" slot-scope="{ option }">
                  {{ option.ragione_sociale }}
                </template>
                <template slot="noOptions">
                  {{ $t("multiselect.noResultAffiliate") }}
                </template>
                <template slot="noResult"
                  >{{ $t("multiselect.noResultLabel") }}
                </template>
              </multiselect>
              <b-form-invalid-feedback id="affiliate-live-feedback">
                {{ $t("validation.required") }}
              </b-form-invalid-feedback>
            </b-overlay>
          </b-form-group>
        </b-col>
        <!-- <b-col lg="6">
          <b-form-group
            id="price-input-group"
            :label="$t('services.price')"
            label-for="price"
          >
            <input type="hidden" />
            <b-overlay :show="loadingPrice" rounded="sm">
              <b-form-input
                id="price"
                type="text"
                v-model="$v.form.price.$model"
                disabled
              ></b-form-input>
            </b-overlay>
          </b-form-group>
        </b-col> -->
      </b-form-row>
      <!-- Actions -->
      <div class="text-right">
        <b-button type="submit" variant="success"
          >{{ $t("buttons.service_request") }}
        </b-button>
      </div>
    </b-form>
    <b-modal
      id="modal-confirmation"
      title="Riepilogo"
      ok-title="Conferma"
      cancel-title="Annulla"
      @ok="onModalConfirm"
    >
      <p class="my-4">
        Cliente: <strong>{{ $loggedUser().first_name + ' ' + $loggedUser().last_name }}</strong>
      </p>
      <p class="my-4" v-if="this.form.service">
        Servizio richiesto: <strong>{{ this.form.service.categoria.name }}</strong>
      </p>
      <p class="my-4">
        Richiesta servizio: dal
        <strong>{{
          new Date(this.form.request_dateStart).toLocaleDateString()
        }}</strong>
        <span v-if="this.form.request_dateEnd">
          al
          <strong v-if="this.form.service.slug!='presenza_week-end_corto' && this.form.service.slug != 'presenza_week-end_lungo'">{{
            new Date(this.form.request_dateEnd).toLocaleDateString()
          }}</strong>
        </span>
      </p>
      <p class="my-4">
        Prezzo: <strong>{{ this.price }}&euro;</strong>
      </p>
      <p class="my-4" v-if="this.form.service && this.form.service.categoria.name != 'COLF' && this.form.service.categoria.name != 'BABYSITTER' ">
        Assistito: <strong>{{ this.form.assisted_name }}</strong>
      </p>
    </b-modal>
  </b-overlay>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
// import router from "@/router";
import { fields, processStatuses } from "@/services/endpoints";
import router from "@/router";

export default {
  mixins: [validationMixin],
  name: "RequestServiceForm",
  props: {
    services: {
      type: Array,
      required: true,
    },
    service: {
      type: Object,
      default: null,
    },
    city: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      form: this.getDefaultFormData(),
      fields: fields,
      showAssistedNameField: true,
      showTime: true,
      showDay: false,
      weekendService: false,
      serviceDescription: null,
      daysServices: [
        "convivenza_h24",
        "notti_in_ospedale",
        "presenza_diurna_a_domicilio",
        "presenza_notturna_a_domicilio",
      ],
      noAssistedNameServices: ["colf", "babysitter"],
      timeServices: ["colf", "babysitter", "assistenza_domiciliare"],
      weekendServices: ["presenza_week-end_corto", "presenza_week-end_lungo"],
    };
  },
  computed: {
    ...mapGetters({
      cities: "cities/cities",
      loadingAffiliates: "services/loadingAffiliates",
      affiliates: "services/affiliates",
      loadingPrice: "services/loadingPrice",
      price: "services/price",
      serviceRequested: "serviceRequests/serviceRequested",
      loadingServiceRequested: "serviceRequests/loadingServiceRequested",
    }),
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    /* price: function (newValue, _oldValue) {
      this.form.price = newValue;
    }, */
    // eslint-disable-next-line no-unused-vars
    serviceRequested: function (newValue, oldValue) {
      if (newValue) {
        // Go to service requests page
        router.push({
          name: "service_requests",
        });
      }
    },
  },
  validations: {
    form: {
      request_dateStart: {
        required: requiredIf(function (form) {
          return !form.days;
        }),
        minValue(val) {
          return !!this.form.days || new Date(val) > new Date();
        },
        isSaturday(val) {
          return !this.weekendService || new Date(val).getDay() === 6;
        },
      },
      request_dateEnd: {
        required: requiredIf(function (form) {
          return !this.weekendService && !form.days;
        }),
        minValue(val) {
          return (
            this.weekendService ||
            !!this.form.days ||
            new Date(val) > new Date()
          );
        },
        afterStart(val) {
          if (
            !this.weekendService &&
            !this.form.days &&
            this.form.request_dateStart
          ) {
            return new Date(val) >= new Date(this.form.request_dateStart);
          }
          return true;
        },
      },
      request_timeStart: {
        required: requiredIf(function () {
          return this.showTime;
        }),
        selected(val) {
          return (
            !this.showTime || (val !== null && val.HH !== "" && val.mm !== "")
          );
        },
      },
      request_timeEnd: {
        required: requiredIf(function () {
          return this.showTime;
        }),
        selected(val) {
          return (
            !this.showTime || (val !== null && val.HH !== "" && val.mm !== "")
          );
        },
      },
      service: {
        required,
      },
      user_id: {
        required,
      },
      assisted_name: {
        required: requiredIf(function () {
          return this.showAssistedNameField;
        }),
      },
      city: {
        required,
      },
      address: {
        required,
      },
      affiliate: {
        required,
      },
      days: {
        required: requiredIf(function (form) {
          return (
            !this.weekendService &&
            (!form.request_dateStart || !form.request_dateEnd)
          );
        }),
      },
      /* price: {
        required,
      }, */
    },
  },
  methods: {
    ...mapActions({
      getCities: "cities/getCities",
      getServices: "services/getAll",
      getAffiliatesByCityId: "services/getAffiliatesByCityId",
      resetAffiliates: "services/resetAffiliates",
      getPriceByServiceAndAffiliate: "services/getPriceByServiceAndAffiliate",
      // resetPrice: "services/resetPrice",
      requestService: "serviceRequests/requestService",
      showError: "alert/error",
    }),
    getDefaultFormData() {
      /* return {
        request_dateStart: "2023-12-23",
        request_timeStart: { HH: "13", mm: "30" },
        request_dateEnd: "2023-12-23",
        request_timeEnd: { HH: "13", mm: "30" },
        service: this.services[0],
        user_id: this.$isLogged() ? this.$loggedUser().pk : null,
        assisted_name: "Test",
        city: this.city,
        address: "Via Boh",
        affiliate: null,
        days: null,
        caretaker_user: null,
        family: this.$isLogged() ? this.$loggedUser().utente.id : null,
        mail_sended: false,
        durata: 0,
      }; */
      return {
        request_dateStart: null,
        request_timeStart: null,
        request_dateEnd: null,
        request_timeEnd: null,
        service: this.service,
        user_id: this.$isLogged() ? this.$loggedUser().pk : null,
        assisted_name: null,
        city: this.city,
        address: null,
        affiliate: null,
        days: null,
        caretaker_user: null,
        family: this.$isLogged() ? this.$loggedUser().pk : null,
        mail_sended: false,
        durata: 0,
      };
    },
    updateServiceDescription() {
      if (!this.form.service) {
        this.serviceDescription = "Selezionare il servizio desiderato";
        return;
      }
      if (
        this.timeServices &&
        this.form.request_dateStart &&
        this.form.request_dateEnd &&
        this.form.request_timeStart &&
        this.form.request_timeEnd &&
        (this.form.service.slug === "colf" ||
        this.form.service.slug === "babysitter" ||
        this.form.service.slug === "assistenza_domiciliare")
      ) {
        this.serviceDescription = `Il servizio si intende svolto dal ${this.form.request_dateStart} alle ${this.form.request_timeStart.HH}:${this.form.request_timeStart.mm} al ${this.form.request_dateEnd} alle ${this.form.request_timeEnd.HH}:${this.form.request_timeEnd.mm}`;
        this.form.assisted_name = ''
        return;
      }
      if (
        this.form.service.slug === "notti_in_ospedale" ||
        this.form.service.slug === "presenza_notturna_a_domicilio"
      ) {
        this.serviceDescription =
          "Il servizio si intende svolto dalle 20.00 alle 08.00";
          this.form.request_timeEnd = {HH: '00', mm: '00'};
          this.form.request_timeStart = {HH: '00', mm: '00'};
        return;
      }
      if (this.form.service.slug === "presenza_diurna_a_domicilio") {
        this.serviceDescription =
          "Il servizio si intende svolto dalle 08.00 alle 20.00";
          this.form.request_timeEnd = {HH: '00', mm: '00'};
          this.form.request_timeStart = {HH: '00', mm: '00'};
        return;
      }
      if (this.form.service.slug === "convivenza_h24") {
        this.serviceDescription =
          "Il servizio si intende svolto a domicilio H24";
          this.form.request_timeEnd = {HH: '00', mm: '00'};
          this.form.request_timeStart = {HH: '00', mm: '00'};
        return;
      }
      if (this.form.service.slug === "presenza_week-end_corto") {
        this.serviceDescription =
          "Il servizio si intende svolto dalle 14.00 del sabato alle 20.00 della domenica";
          this.form.request_dateEnd = '1970-01-01'
          this.form.request_timeEnd = {HH: '00', mm: '00'};
          this.form.request_timeStart = {HH: '00', mm: '00'};
        return;
      }
      if (this.form.service.slug === "presenza_week-end_lungo") {
        this.serviceDescription =
          "Il servizio si intende svolto dalle 14.00 del sabato alle 08.00 del lunedì";
          this.form.request_dateEnd = '1970-01-01'
          this.form.request_timeEnd = {HH: '00', mm: '00'};
          this.form.request_timeStart = {HH: '00', mm: '00'};
        return;
      }
      this.serviceDescription =
        "Selezionare data e ora di inizio e fine del servizio desiderato";
    },
    // eslint-disable-next-line no-unused-vars
    onCityChanged(value, _property) {
      this.getAffiliatesByCityId(value.id);
    },
    // eslint-disable-next-line no-unused-vars
    onCityRemoved(_removedValue, _property) {
      this.form.affiliate = null;
      // this.form.price = null;
      this.resetAffiliates();
      // this.resetPrice();
    },
    // eslint-disable-next-line no-unused-vars
    onServiceOrAffiliateChanged(value, property) {
      // this.form.days = null;
      this.$v.form.days.$model = null;
      this.showAssistedNameField = !this.noAssistedNameServices.includes(
        this.form.service.slug
      );
      this.showDay = this.daysServices.includes(this.form.service.slug);
      this.showTime = this.timeServices.includes(this.form.service.slug);
      this.weekendService = this.weekendServices.includes(
        this.form.service.slug
      );
      this.updateServiceDescription();
    },
    onDaysValueChanged() {
      if (this.form.days) {
        this.form.request_dateStart = '1970-01-01';
        this.form.request_dateEnd = '1970-01-01';
        this.form.request_timeStart = {HH: '00', mm: '00'};
        this.form.request_timeEnd = {HH: '00', mm: '00'};

      }else{
        this.form.request_dateStart = null;
        this.form.request_dateEnd = null;
        this.form.request_timeStart = {HH: '00', mm: '00'};
        this.form.request_timeEnd = {HH: '00', mm: '00'};
      }
    },
    // eslint-disable-next-line no-unused-vars
    onServiceOrAffiliateRemoved(_removedValue, _property) {
      /* this.form.price = null;
      this.resetPrice(); */
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    async onFormSubmit() {
      this.$v.form.$touch();
      /* console.log("showAssistedNameField", this.showAssistedNameField);
      console.log("showDay", this.showDay);
      console.log("showTime", this.showTime);
      console.log("weekendService", this.weekendService);
      console.log("endRequired", !this.weekendService && !this.form.days); */
      if (this.$v.form.$anyError) {
        console.log("Form errors", this.$v.form);
        return;
      }
      if(this.form.affiliate.ragione_sociale == "Agenzia MyCare"){
        this.form.affiliate.id = 1;
      }
      // Get price from APIs
      await this.getPriceByServiceAndAffiliate({
        service_id: this.form.service.id,
        affiliate_id: this.form.affiliate.id,
        // end_date: this.form.request_dateEnd+" "+this.form.request_timeEnd,
        // start_date: this.form.request_dateStart+" "+this.form.request_timeStart ,
        end_date: this.form.request_dateEnd+' '+this.form.request_timeEnd.HH +":"+this.form.request_timeEnd.mm+':00' || "1970-01-01 00:00:00",
        start_date: this.form.request_dateStart+' '+this.form.request_timeStart.HH +":"+this.form.request_timeStart.mm+':00'  || "1970-01-01 00:00:00",
       

        days: parseInt(this.form.days || 0),
      });
      if (!this.price) {
        this.showError("Errore durante il calcolo del costo del servizio");
        // @todo: Re-enable
        // return;
      }
      console.log("price", this.price);
      this.$bvModal.show("modal-confirmation");
    },
    onModalConfirm() {
      let data = JSON.parse(JSON.stringify(this.form));
      // For APIs
      data.family_user = this.form.family.id;
      data.user = this.form.user_id;
      data.service = this.form.service.id;
      const dateStart = this.form.request_dateStart || "1970-01-01";
      if (this.form.request_timeStart) {
        data.dateInizio = 
          dateStart +
          " " +
          this.form.request_timeStart.HH +
          ":" +
          this.form.request_timeStart.mm +
          ":00";
      } else {
        data.dateInizio = dateStart + " 00:00:00";
      }
      const dateEnd = this.form.request_dateEnd || "1970-01-01";
      if (this.form.request_dateEnd && this.form.request_timeEnd) {
        data.dateFine = 
          dateEnd +
          " " +
          this.form.request_timeEnd.HH +
          ":" +
          this.form.request_timeEnd.mm +
          ":00";
      } else {
        data.dateFine = dateEnd + " 00:00:00";
      }
      data.city = this.form.city.id;
      data.affiliate_user = this.form.affiliate.id;
      data.process_status = processStatuses.notProcessed;
      data.caretaker_user = 1;
      data.price = this.price;
      data.days = parseInt(this.form.days || "0");

      if(this.form.affiliate.ragione_sociale == "Agenzia MyCare"){
        data.affiliate_user = 1;
      }

      console.log("Submitting service request", data);
      this.requestService(data);
    },
    resetForm() {
      this.form = this.getDefaultFormData();
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
  },
  mounted() {
    if (!this.services || !this.services.length) {
      // Load from API
      console.log("Loading services from API");
      this.getServices();
    }
    if (!this.cities || !this.cities.length) {
      // Load from API
      console.log("Loading cities from API");
      this.getCities();
    }
    if (this.city) {
      this.getAffiliatesByCityId(this.city.id);
    }
  },
};
</script>

<style scoped>
::v-deep .vue__time-picker input {
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-family: "Montserrat", sans-serif;
}

::v-deep .vue__time-picker input:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}

::v-deep .vue__time-picker.is-invalid input {
  border-color: #dc3545;
}
</style>
